<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <!-- แสดงภาพ -->
    <Photo
      v-if="picturesShow.ShowPhoto"
      :showphoto="picturesShow.ShowPhoto"
      :showphotolink="picturesShow.ShowPhotoLink"
      @emitshowphoto="closePhoto"
    />
    <!-- แสดงรูปภาพที่ถ่าย
    <h3>Captured Images:</h3>
      <div v-for="(image, index) in photoList" :key="index" class="captured-image">
        <img :src="image" :alt="'Captured Image ' + (index + 1)" width="200">
        <v-btn @click="downloadImage(image, index)">Download Image {{ index + 1 }}</v-btn>
      </div> -->

    <v-navigation-drawer app right permanent v-if="UpDownloadingGoogleDrive">
      <v-list-item-content>
        <v-list-item>
                <v-radio-group
                  v-model="selectedTypeCamera"
                  @change="btnSaveCameratype()" 
                >
                <v-row>
                <v-col cols="8">
                  <v-radio
                    label="ถ่ายภาพ"
                    :value="0"
                  ></v-radio>
                </v-col>
                <v-col cols="4">
                  <v-radio
                    label="วีดีโอ"
                    :value="1"
                  ></v-radio>
                </v-col>

              </v-row>
                </v-radio-group>
              
          
        </v-list-item>
        <v-list-item>
          <video
            ref="video"
            autoplay
            playsinline
            muted
            style="width: 230px; height: 150px; background-color: black"
          ></video>
        </v-list-item>
        <v-list-item class="py-4" >
          <v-row   align="center">
            <v-col class="text-center" cols="2">กล้อง </v-col>
            <v-col cols="8">
            <v-select
              @change="startCamera()"
              :disabled="videoDevices.length === 0"
              v-model="selectedCameraId"
              hide-details
              outlined
              label="เลือกกล้อง"
              :items="videoDevices"
              :item-text="(item) => item.label"
              :item-value="(item) => item.deviceId"
              max-width="50px"
            >
            </v-select>
          </v-col>
          </v-row>
        
        </v-list-item>
        <v-list-item>
          <v-row justify="center">
            <v-col cols="12">
              <v-btn height="72" min-width="100%" @click="captureImage()">
                <h1 > ถ่ายรูป</h1>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item v-if="isGoogleDrive  && selectedTypeCamera == 1 ">
          <v-row>
                  <v-col cols="6" class="pr-0" style="text-align: end">
                    <span>สถานะกล้อง : </span>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="isRecording"
                    class="pl-1"
                    style="text-align: start"
                  >
                    <font color="red">กำลังบันทึก</font>
                  </v-col>
                  <v-col
                    cols="6"
                    v-else-if="isPaused"
                    class="pl-1"
                    style="text-align: start"
                    ><font color="yellow">หยุดชั่วคราว</font>
                  </v-col>
                  <v-col cols="6" v-else class="pl-1" style="text-align: start">
                    <font>หยุดบันทึก</font>
                  </v-col>
          </v-row>
        </v-list-item>
        <v-list-item v-if="isGoogleDrive  && selectedTypeCamera == 1 ">
          <v-row>
            <v-col cols="12" class="text-center">
              <span v-if="isRecording || elapsedTime > 0"
                >Recording time: {{ formattedElapsedTime }}</span
              >
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item v-if="isGoogleDrive  && selectedTypeCamera == 1 ">
          <v-card width="100%" >  
            <v-card-text>
              <v-row justify="center">
                    <v-col cols="4">
                      <v-btn
                        icon
                        @click="startRec()"
                        :disabled="!selectedCameraId || isRecording"
                      >
                        <v-icon size="28" color="red darken-3"
                          >fa-solid fa-circle</v-icon
                        >
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        icon
                        @click="pauseRec()"
                        :disabled="!isRecording || isPaused"
                      >
                        <v-icon size="28" color="yellow darken-3"
                          >fa-solid fa-pause</v-icon
                        >
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn icon @click="stopRec()" :disabled="!isRecording">
                      <v-icon size="28" color="black darken-3"
                        >fa-solid fa-stop</v-icon
                      >
                    </v-btn>
                
                    </v-col>
                </v-row>
            </v-card-text>
         
          </v-card>
        </v-list-item>
        <v-list-item
          >สำเร็จ {{ countSuccessUpload }} /{{
            isWaitingUploadGoogleDrive.length
          }}
        </v-list-item>
        <v-list-item
          v-for="item in isWaitingUploadGoogleDrive"
          :key="item.fileName"
        >
          <img :src="item.thumbnail" width="50" /> ชื่อ :
          {{ item.fileName }} สถานะ : {{ item.status }}
        </v-list-item>
      </v-list-item-content>
    </v-navigation-drawer>

    <v-card :class="UpDownloadingGoogleDrive ? 'header' : ''">
      <v-card-text>
        <table style="width: 100%">
          <td style="width: 80px">
            <b> OrderID :</b>
          </td>
          <td style="width: 150px" class="px-1">
            <v-text-field
              autofocus
              v-model="OrderID"
              ref="OrderID"
              densed
              hide-details="auto"
              @keyup.enter="btnSearchOrderID()"
            >
            </v-text-field>
          </td>
          <td>
            <v-btn color="primary" @click="btnSearchOrderID()">ค้นหา</v-btn>
          </td>

          <td class="jt_end">
            <v-btn @click="btnBack()">ย้อนกลับ</v-btn>
            <v-list-item-icon>
              <v-icon
                v-if="isGoogleDrive"
                @click="UpDownloadingGoogleDrive = !UpDownloadingGoogleDrive"
                >mdi-dots-vertical</v-icon
              >
            </v-list-item-icon>
          </td>
        </table>
      </v-card-text>
    </v-card>
    <!-- ส่วนของข้อมูลทั้งหมด -->
    <div
      v-if="show"
      class="mt-8"
      :class="UpDownloadingGoogleDrive ? 'margin-left256' : ''"
    >
      <!-- ที่อยู่ผู้ส่ง -->
      <v-row>
        <v-col cols="6">
          <v-card
            style="height: 200px"
            v-if="
              !platformReferenceSourceID.includes(orderInfo.referenceSourceID)
            "
            elevation="1"
            class="v-card-margin-bottom-30"
          >
            <v-card-title>
              ที่อยู่ผู้ส่ง
              <v-spacer></v-spacer>
              <v-btn icon @click="btnEditAddress('sender')">
                <v-icon size="16" color="orange darken-3">fa-edit</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="pb-3">
                <v-col cols="4" md="3" lg="2"> ชื่อผู้ส่ง : </v-col>
                <v-col cols="8" md="9" lg="10">
                  {{ orderInfo.senderAddress.name }}
                </v-col>
              </v-row>
              <v-row class="pb-3">
                <v-col cols="4" md="3" lg="2"> เบอร์ : </v-col>
                <v-col cols="8" md="9" lg="10">
                  {{ orderInfo.senderAddress.phone }}
                </v-col>
              </v-row>
              <v-row class="pb-3">
                <v-col cols="4" md="3" lg="2"> ที่อยู่ : </v-col>
                <v-col cols="8" md="9" lg="10">
                  {{ orderInfo.senderAddress.address }} &nbsp;
                  {{ orderInfo.senderAddress.subDistrict }} &nbsp;
                  {{ orderInfo.senderAddress.district }} &nbsp;
                  {{ orderInfo.senderAddress.province }} &nbsp;
                  {{ orderInfo.senderAddress.postcode }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            style="height: 200px"
            v-if="
              !platformReferenceSourceID.includes(
                orderInfo.referenceSourceID
              ) && orderInfo.shippingAddressID
            "
            elevation="1"
            class="v-card-margin-bottom-30"
          >
            <v-card-title>
              ที่อยู่ผู้รับ
              <v-spacer></v-spacer>
              <v-btn icon @click="btnEditAddress('receiver')">
                <v-icon size="16" color="orange darken-3">fa-edit</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="pb-3">
                <v-col cols="4" md="3" lg="2"> ชื่อผู้รับ : </v-col>
                <v-col cols="8" md="9" lg="10">
                  {{ orderInfo.receiverAddress.name }}
                </v-col>
              </v-row>
              <v-row class="pb-3">
                <v-col cols="4" md="3" lg="2"> เบอร์ : </v-col>
                <v-col cols="8" md="9" lg="10">
                  {{ orderInfo.receiverAddress.phone }}
                </v-col>
              </v-row>
              <v-row class="pb-3">
                <v-col cols="4" md="3" lg="2"> ที่อยู่ : </v-col>
                <v-col cols="8" md="9" lg="10">
                  {{ orderInfo.receiverAddress.address }} &nbsp;
                  {{ orderInfo.receiverAddress.subDistrict }} &nbsp;
                  {{ orderInfo.receiverAddress.district }} &nbsp;
                  {{ orderInfo.receiverAddress.province }} &nbsp;
                  {{ orderInfo.receiverAddress.postcode }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- ที่อยู่ผู้รับ -->

      <!-- แพลตฟอร์ม -->
      <v-card
        v-if="this.orderItemTemp.length > 0"
        elevation="1"
        class="v-card-margin-bottom-30"
      >
        <v-card-title> แพลตฟอร์ม </v-card-title>
        <v-card-text>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> เลขอ้างอิง : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.referenceNumber }}
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> ช่องทาง : </v-col>
            <v-col cols="8" md="9" lg="10">
              <a
                v-if="
                  orderInfo.channelLogo != null && orderInfo.channelLogo != ''
                "
                icon
                @click="openPhoto(orderInfo.channelLogo)"
              >
                <v-img
                  style="margin: 5px"
                  v-if="
                    orderInfo.channelLogo != '' && orderInfo.channelLogo != null
                  "
                  :src="orderInfo.channelLogo"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  max-width="30"
                  max-height="30"
                ></v-img>
              </a>
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3" v-if="orderInfo.tempSenderAddress">
            <v-col cols="4" md="3" lg="2"> ผู้ส่ง : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.tempSenderAddress }}
            </v-col>
          </v-row>
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> ผู้รับ : </v-col>
            <v-col cols="8" md="9" lg="10">
              {{ orderInfo.tempAddress }}
            </v-col>
          </v-row>

          <!-- PC hidden-xs-only -->
          <v-row class="pl-6 pb-3">
            <v-col cols="4" md="3" lg="2"> ที่ต้องแพ็ค : </v-col>
            <v-col cols="8" md="9" lg="10" class="hidden-xs-only">
              <v-simple-table class="packhai-border-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center" width="90px">รูป</th>
                      <th class="text-left" width="650px">ชื่อ</th>
                      <th class="text-left" width="100px">SKU</th>
                      <th class="text-right" width="70px">จำนวน</th>
                      <th class="text-right" width="170px">ราคาต่อหน่วย</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in orderItemTemp" :key="item.name">
                      <td class="text-center">
                        <a
                          v-if="item.imgUrl != null && item.imgUrl != ''"
                          icon
                          @click="openPhoto(item.imgUrl)"
                        >
                          <v-img
                            style="margin: 5px"
                            :src="item.imgUrl"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            width="50px"
                            height="50px"
                          ></v-img>
                        </a>
                      </td>
                      <td class="text-left">
                        <div v-if="item.notSku" class="color-red">
                          {{ item.name }}
                          <br />
                          ไม่พบสินค้าในระบบ
                        </div>
                        <div v-else>
                          {{ item.name }}
                        </div>
                      </td>
                      <td class="text-left">
                        <div v-if="item.notSku" class="color-red">
                          {{ item.sku }}
                        </div>
                        <div v-else>
                          {{ item.sku }}
                        </div>
                      </td>
                      <td class="text-right">{{ item.quantity }}</td>
                      <td class="text-right">{{ item.unitPrice }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- รูปแบบพัสดุ -->
      <v-card elevation="1" ref="packingItem" class="v-card-margin-bottom-30">
        <v-card-title> รูปแบบพัสดุ </v-card-title>
        <v-card-text class="pl-10">
          <v-row>
            <v-col cols="12">
              <span v-if="orderInfo.isCOD == false">
                <v-chip color="info" outlined> No-COD </v-chip>
              </span>
              <span v-else>
                <v-chip class="mr-5" color="error"> COD </v-chip>
                <!-- {{orderInfo}} -->
                <!-- {{ orderDetail }} -->
                <span
                  v-if="
                    orderInfo.codAmount != null && orderInfo.codAmount != ''
                  "
                >
                  ยอด {{ formatMoney(orderInfo.codAmount) }} บาท
                </span>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- หมายเหตุ -->

      <v-card elevation="1" ref="PackageSize" class="v-card-margin-bottom-30">
        <v-card-title> หมายเหตุ </v-card-title>
        <v-card-text class="pl-10"> .... </v-card-text>
      </v-card>
      <v-card elevation="1" style="margin-bottom: 60px !important">
        <v-card-title> รายการพัสดุต้องแพ็ค </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-tabs v-model="orderItem.tab">
                <v-tab
                  v-for="i in orderItem.packages"
                  class="box-shadow"
                  :key="i.packageNo"
                  @click="orderItem.tab = i.packageNo"
                >
                  พัสดุ # {{ i.packageNo }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-tabs-items v-model="orderItem.tab" touchless>
            <v-tab-item
              v-for="packageItem in orderItem.packages"
              :key="packageItem.packageNo"
            >
              <br />
              <table>
                <tr>
                  <td>Barcode/SKU :</td>
                  <td class="pl-3">
                    <v-text-field
                      style="width: 250px"
                      v-model="packingCodeAdd"
                      id="packingCodeAd"
                      outlined
                      hide-details
                      dense
                      @keyup.enter="addItemPacking(packageItem)"
                    ></v-text-field>
                  </td>
                  <td class="pl-3">
                    <v-btn
                      color="info"
                      outlined
                      @click="openAddItemsIncrease(packageItem)"
                    >
                    <template v-if="addItemsIncrease > 1">
                       <h4> = {{addItemsIncrease}}</h4>
               
                    </template>
                    <template v-else>
                      <v-icon size="14" class="pr-1">fa-solid fa-plus</v-icon>
                      เพิ่มรายการ
                  </template>
                </v-btn>
                    <!-- <v-btn
                      color="info"
                      outlined
                      @click="FullItem()"
                    >
                   
               
                   
                      เต็มหมด
                    
                </v-btn> -->
                     
                  </td>
                  <td>
                    <v-btn
                      v-if="ShowSplitMenu(packageItem.items)"
                      color="primary"
                      outlined
                      @click="btnSplitPackage(packageItem.items,packageItem.packageNo)"
                    >
                      แยกพัสดุ
                    </v-btn>
                  </td>
                </tr>
              </table>

              <v-data-table
                :headers="orderItem.headers"
                :items="packageItem.items"
                fixed-header
                :items-per-page="9999999999"
                hide-default-footer
                class="elevation-1 mt-3 packhai-table packhai-border-table"
              >
                <template v-slot:item="{ item }">
                  <tr :class="changeColorHeader(item)">
                    <td class="text-center">
                      <a
                        v-if="item.photoLink != null && item.photoLink != ''"
                        icon
                        @click="openPhoto(item.photoLink)"
                      >
                        <v-img
                          style="margin: 5px"
                          v-if="item.photoLink != '' && item.photoLink != null"
                          :src="item.photoLink"
                          aspect-ratio="1"
                          class="grey lighten-2"
                          max-width="80"
                          max-height="80"
                        ></v-img>
                      </a>
                    </td>
                    <td>{{ item.productName }}</td>
                    <td>{{ item.sku }}</td>
                    <td>{{ item.barcode }}</td>
                    <td class="text-center">
                      <span v-if="item.isLock">{{ item.quantity }}</span>
                    </td>
                    <td class="text-center">
                      <span v-if="item.packedQuantity">
                        {{ item.packedQuantity }}
                      </span>
                      <span v-else> 0 </span>
                    </td>
                    <td class="text-center">
                      <a
                        @click="
                          DeleteItemEachPacking(
                            packageItem.items.indexOf(item),
                            packageItem,
                            1
                          )
                        "
                        >ลบจำนวน</a
                      >
                    </td>

                    <td>
                      <span v-if="!item.quantity && !item.isBox">
                        ไม่ใช้สินค้าที่ต้องแพ็ค
                      </span>
                      <span v-else-if="item.isBox"></span>
                      <span
                        v-else-if="item.quantity - item.packedQuantity > 0"
                        style="color: red"
                      >
                        ยังขาดอีก
                        {{ item.quantity - item.packedQuantity }}</span
                      >
                      <span
                        v-else-if="item.quantity - item.packedQuantity < 0"
                        style="color: red"
                      >
                        เกิน {{ item.packedQuantity - item.quantity }}</span
                      >

                      <span
                        v-else-if="(item.quantity = item.packedQuantity)"
                        style="color: green"
                      >
                        ครบ
                      </span>
                      <span v-else> </span>
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <!-- ขนส่ง -->
              <div
                v-if="
                  !platformReferenceSourceID.includes(
                    orderInfo.referenceSourceID
                  )
                "
                elevation="1"
                class="mt-10"
              >
                <h3 class="mb-5">ขนส่ง</h3>

                <v-radio-group
                  v-model="packageItem.uniqueID"
                  row
                  @change="changedExpress($event, packageItem)"
                >
                  <v-radio
                    style="margin-top: 20px; margin-left: 20px"
                    v-for="items in orderInfo.expressChannelToShow"
                    :key="items.indexOf"
                    :value="items.uniqueID"
                    :label="items.expressCompanyName"
                  >
                    <template v-slot:label>
                      <v-img
                        :src="items.expressCompanyLogo"
                        width="50"
                        height="auto"
                      ></v-img>
                      <h4>&nbsp;&nbsp;{{ items.expressCompanyName }}</h4>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>

              <!-- แสดงรูปภาพที่ถ่าย -->
              <template  >
                <h3 class="mt-10 mb-4">รูปถ่าย</h3>

                <v-card
                  class="mx-auto"
                  max-width="100%"
                  style="
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    overflow: auto;
                    height: 350px;
                    overflow-y: auto;
                  "
                >
                  <v-container class="pa-1">
                    <v-item-group v-model="selection" multiple>
                      <v-row>
                        <v-col
                          v-for="(image, index) in packageItem.photoList"
                          :key="index"
                          cols="auto"
                          class="captured-image ma-1"
                          style="
                            border: 1px solid #ddd;
                            border-radius: 8px;
                            padding: 8px;
                          "
                        >
                          <v-item v-slot="{ isSelected, toggle }">
                            <v-img
                              :src="image.pic"
                              class="text-right pa-2"
                              :alt="'Captured Image ' + (index + 1)"
                              max-width="240"
                              max-height="240"
                              cover
                              @click="openPhoto(image.pic)"
                            >
                              <v-btn
                                icon
                                @click="btnDeleteImagesCaptured(index)"
                              >
                                <v-icon size="25" color="red darken-3"
                                  >mdi-close</v-icon
                                >
                              </v-btn>
                            </v-img>
                          </v-item>
                        </v-col>
                      </v-row>
                    </v-item-group>
                  </v-container>
                </v-card>
              </template>
              <!-- ขนาดบรรจุภัณฑ์ -->

              <h3 class="mt-10">ขนาดบรรจุภัณฑ์</h3>

              <v-row class="mt-5">
                <!-- คำนวณค่าแพ็ค -->
                <v-col>
                  <!-- น้ำหนัก(ฺBT) -->
                  <!-- <v-row class="mt-2">
                <v-col cols="5" class="text-center">
                  <span>น้ำหนัก(ฺBT) : </span>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="packageItem.weightBT"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row> -->
                  <!-- น้ำหนัก -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>น้ำหนัก : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        @change="filterDeliveryPrices(packageItem)"
                        v-model="packageItem.weight"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- กว้าง cubic3 -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>กว้าง : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        @change="filterDeliveryPrices(packageItem)"
                        v-model="packageItem.cubic3"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- ยาว cubic1 -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>ยาว : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        @change="filterDeliveryPrices(packageItem)"
                        v-model="packageItem.cubic1"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- สูง cubic2 -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>สูง : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        @change="filterDeliveryPrices(packageItem)"
                        v-model="packageItem.cubic2"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- ค่ากล่อง -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>ค่ากล่อง : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="packageItem.boxPrice"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- ค่าแพ็ค -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>ค่าแพ็ค : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="packageItem.packPrice"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- ค่า Bubble -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>ค่า Bubble : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="packageItem.bubblePrice"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- ค่าส่ง -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>ค่าส่ง : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        :readonly="platformReferenceSourceID.includes(orderInfo.referenceSourceID)"
                        v-model="packageItem.deliveryPrice"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- % COD -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>% COD : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        :readonly="!orderInfo.isCOD"
                        @change="calculateCodPercent(packageItem)"
                        v-model="packageItem.codPercent"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- ค่า COD -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>ค่า COD : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        readonly
                        v-model="packageItem.codCharged"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- พื้นที่ห่างไกล -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>พื้นที่ห่างไกล : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="packageItem.remotePrice"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- ส่วนลด -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>ส่วนลด : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="packageItem.discount"
                        outlined
                        type="number"
                        dense
                        hide-details
                        @input="packageItem.discount = Math.abs(packageItem.discount)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- ค่าใช้จ่ายอื่น -->
                  <v-row class="mt-2">
                    <v-col cols="5" class="text-left">
                      <span>ค่าใช้จ่ายอื่น : </span>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="packageItem.otherServicePrice"
                        outlined
                        type="number"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- เลือกขนาดของกล่อง -->
                <v-col cols="8">
                  <v-checkbox
                    v-model="packageItem.bubble"
                    label="มีใช้บริการห่อ Bubble"
                    @click="btnBoxSize(packageItem)"
                  ></v-checkbox>
                  
                  <v-data-table
                    v-model="packageItem.selected"
                    :headers="orderPackageSize.headers"
                    :items="packageItem.postboxes"
                    hide-default-footer
                    height="50vh"
                    fixed-header
                    show-select
                    single-select
                    :items-per-page="packageItem.postboxes.length"
                    item-key="name"
                    item-value="item"
                    class="elevation-1"
                    return-object
                    @item-selected="selectedBox($event, packageItem)"
                  >
                    <template v-slot:header.data-table-select="{ on, props }">
                      <span>เลือก</span>
                    </template>
                    <template v-slot:item.size="{ item }">
                      <span></span>
                      <span>{{ item.cubic1 }} x </span>
                      <span>{{ item.cubic2 }} x </span>
                      <span>{{ item.cubic3 }}</span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>
    <v-footer
      app
      fixed
      class="bg-indigo-lighten-1 text-center d-flex flex-column"
      v-if="show"
    >
      <v-card style="width: 100%">
        <v-row class="ma-1 item-center">
          <v-col cols="6" class="jt_Start item-center">
           </v-col>
          <v-col cols="6" class="jt_end item-center">
            <v-btn color="primary" @click="btnSubmitSavePack()">บันทึก</v-btn>
            <v-btn class="ml-2" color="success" @click="btnSubmitAwb()"
              >บันทึกและปริ้น</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-footer>

    <!-- แยกพัสดุ -->
    <v-dialog
      v-model="splitPackagePopup.dialog"
      scrollable
      :max-width="splitPackagePopup.MaxWidth"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="v-list-item__content pa-2">
              ยืนยันพัสดุที่ต้องการแยก
            </div>
            <div>
              <v-btn icon @click="splitPackagePopup.dialog = false"
                ><v-icon color="danger">mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </v-card-title>
        <v-card>
          <v-data-table
            :headers="splitPackageDataSet.headers"
            :items="splitPackageDataSet.dataSet"
            height="50vh"
            fixed-header
            :items-per-page="9999999999"
            hide-default-footer
            class="elevation-1"
            item-value="productName"
            item-key="productName"
          >
            <template v-slot:item.photoLink="{ item }">
              <a
                v-if="item.photoLink != null && item.photoLink != ''"
                icon
                @click="openPhoto(item.photoLink)"
              >
                <v-img
                  style="margin: 0px 0px 0px 20px"
                  v-if="item.photoLink != '' && item.photoLink != null"
                  :src="item.photoLink"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  max-width="50"
                  max-height="50"
                ></v-img>
              </a>
            </template>
          </v-data-table>
        </v-card>

        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              เลือกทั้งหมด {{ splitPackageDataSet.dataSet.length }} รายการ
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="primary" @click="btnSubmitSplitPackage()">
                ดำเนินการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- แก้ไขที่อยู่ผู้ส่ง -->
    <v-dialog
      v-model="editAddressPopup.dialog"
      scrollable
      :max-width="editAddressPopup.MaxWidth"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span>แก้ไขที่อยู่ผู้ส่ง</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="editAddressPopup.dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row row>
            <v-col cols="12" md="12" class="pt-2">
              <v-row row>
                <v-col cols="12" md="3" class="py-0">
                  <h4>ชื่อ :</h4>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    :value="editAddressDataSet.name"
                    @change="(value) => (editAddressDataSet.name = value)"
                    autocomplete="off"
                    ref="refeEditAddressDataSetName"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="12" class="pt-2">
              <v-row row>
                <v-col cols="12" md="3" class="py-0">
                  <h4>เบอร์โทร :</h4>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    :value="editAddressDataSet.phone"
                    @change="(value) => (editAddressDataSet.phone = value)"
                    autocomplete="off"
                    ref="refeEditAddressDataSetPhone"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="12" class="pt-2">
              <v-row row>
                <v-col cols="12" md="3" class="py-0">
                  <h4>ที่อยู่ :</h4>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    :value="editAddressDataSet.address"
                    @change="(value) => (editAddressDataSet.address = value)"
                    autocomplete="off"
                    ref="refeEditAddressDataSetAddress"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row row>
            <v-col cols="12" md="12" class="pt-2">
              <v-row row>
                <v-col cols="12" md="3" class="py-0">
                  <h4>ไปรษณีย์ :</h4>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-autocomplete
                    v-model="editAddressDataSet.postcodeMappingID"
                    ref="refeEditAddressDataSetPostcode"
                    placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="
                      (item) =>
                        item.Postcode +
                        ' ' +
                        item.Province +
                        ' ' +
                        item.District +
                        ' ' +
                        item.SubDistrict
                    "
                    :item-value="(item) => item.Id"
                    outlined
                    required
                    hide-details
                    dense
                  ></v-autocomplete>
                 </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" class=" ">
              <v-row row>
                <v-col cols="12" md="12" align="left">
                  <v-btn
                    dark
                    color="primary"
                    @click="btnSummitEditAddress()"
                    class="mr-1"
                    ><v-icon left>mdi-content-save</v-icon> บันทึก</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import Photo from "@/website/components/photo";

import {
  shopService_dotnet,
  orderService_dotnet,
  stockService_dotnet,
  branchService_dotnet,
  ecomService_dotnet,
  generalService_dotnet,
  logisticService_dotnet,
} from "@/website/global";
import { ChangeReferenceInfo, formatMoney } from "@/website/global_function";
import { AlertError } from "@/website/global_alert";
import RecordRTC from "recordrtc";
import moment from "moment";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { retry } from "async";

export default {
  components: {
    Loading,
    Photo,
  },

  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    page_loading: true,
    loading: false,
    OrderID: null,
    platformReferenceSourceID: [3, 4, 5, 11],
    orderInfo: {},
    orderDetail: { receiver: "", sender: "" },
    orderItem: {
      headers: [
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "100px",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "productName",
          width: "300px",
        },
        {
          text: "SKU",
          align: "left",
          sortable: false,
          value: "sku",
          width: "150px",
        },
        {
          text: "Barcode",
          align: "left",
          sortable: false,
          value: "barcode",
          width: "200px",
        },
        {
          text: "จำนวนต้องแพ็ค",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "120px",
        },
        {
          text: "จำนวนแพ็คจริง",
          align: "center",
          sortable: false,
          value: "packedQuantity",
          width: "120px",
        },
        {
          text: "ลบจำนวน",
          align: "center",
          sortable: false,
          value: "deleteQuantity",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "status",
        },
      ],
      packages: [],
      isLock: false,
      tab: 0,
      tab_list: [],
      selected: [],
    },
    orderItemTemp: [],
    orderPackageSize: {
      bubble: false,
      weightBT: 0,
      weight: 0,
      long: 0,
      width: 0,
      height: 0,
      boxValue: 0,
      PackValue: 0,
      bubbleFee: 0,
      deliveryFee: 0,
      codPercent: 0,
      codAmount: 0,
      remoteArea: 0,
      discount: 0,
      otherExpenses: 0,
      headers: [
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "name",
          width: "50px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "size",
          width: "50px",
        },
        {
          text: "ค่ากล่อง",
          align: "center",
          sortable: false,
          value: "boxSellPrice",
          width: "50px",
        },
        {
          text: "ค่าแพ็ค",
          align: "center",
          sortable: false,
          value: "packPrice",
          width: "50px",
        },
        {
          text: "ค่า Bubble",
          align: "center",
          sortable: false,
          value: "bubblePrice",
          width: "50px",
        },
      ],
      boxSize: [
        {
          name: "D",
          cubic1: 22, //ยาว
          cubic2: 35, //สูง
          cubic3: 14, //กว้าง
          boxSellPrice: 5,
          packAndBoxPrice: 16,
          packPrice: 0, //ค่าแพ็ค
          bubblePrice: 3,
        },
        {
          name: "E",
          cubic1: 24, //ยาว
          cubic2: 40, //สูง
          cubic3: 17, //กว้าง
          boxSellPrice: 17,
          packAndBoxPrice: 17,
          packPrice: 0, //ค่าแพ็ค
          bubblePrice: 5,
        },
      ],
      selected: [],
    },
    currentShopID: null,
    //ส่วนของ Google Drive
    folderSub: null,
    jsonClent: null,
    //แสดงภาพ
    picturesShow: {
      ShowPhotoLink: "",
      ShowPhoto: false,
    },

    //เสียง
    audioScan: new Audio(require("../deliveryNote/success_scan.mp3")),
    audioBuzzer: new Audio(require("../deliveryNote/buzzer.wav")),

    //รหัสที่เพิ่มสินค้าที่แพ็ค
    packingCodeAdd: null,
    //นำนวนที่ต้องการเพิ่ม
    addItemsIncrease: 1,
    show: false,

    //ถ่ายภาพ
    img: null,
    camera: null,
    deviceId: null,
    devices: [],

    selection: [],
    stopSaveGoogleDrive : false,

    splitPackageDataSet: {
      headers: [
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "50px",
        },
        {
          text: "ชื่อ",
          align: "center",
          sortable: false,
          value: "productName",
          width: "50px",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "50px",
        },
        {
          text: "Barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "50px",
        },
        // {
        //   text: "จำนวนที่มี",
        //   align: "center",
        //   sortable: false,
        //   value: "quantity",
        //   width: "50px",
        // },
        {
          text: "จำนวนที่ต้องการแยก",
          align: "center",
          sortable: false,
          value: "packedQuantity",
          width: "50px",
        },
      ],
      dataSet: [],
      selected: [],
    },
    splitPackagePopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
    },
    SplitPackageNo: null,
    selectedStock: [],

    //วีดีโอ
    recorder: null,
    disabled: false,
    downloadReady: false,
    recordedBlob: null,
    isVideoVisible: false,
    photoList: [],
    thumbnailImg: null,

    videoDevices: [], // เก็บรายการกล้อง
    selectedCameraId: null, // เก็บ deviceId ของกล้องที่เลือก
    isRecording: false, // สถานะการบันทึก
    isPaused: false, // สถานะการหยุดชั่วคราว
    elapsedTime: 0, // เวลาบันทึกที่ใช้ไปในมิลลิวินาที
    timer: null, // ตัวจับเวลาสำหรับการบันทึก
    IsFirstOpenCamera: false,
    openCamera: false,

    isGoogleDrive: false,
    isGetFolderGoogleDrive: "",
    isWaitingUploadGoogleDrive: [],
    UpDownloadingGoogleDrive: false,
    countSuccessUpload: 0,

    selectedCameraPopup: {
      dialog: false,
      MaxWidth: "45%",
      MaxHeight: 60,
    },
    selectedTypeCamera: 0,

    editAddressDataSet: {
      name: null,
      phone: null,
      address: null,
      postcode: null,
      postcodeMappingId: null,
      id: null,
      shopCustomerID: null,
    },
    // แก้ไขที่อยู่
    editAddressPopup: {
      dialog: false,
      MaxWidth: "45%",
      MaxHeight: 60,
      typeAddress: null,
    },
    postcode_mapping: [],
  }),
  async created() {
    console.log("11.02 Check refreshToken");

    this.isGetFolderGoogleDrive = await this.getGoogleDriveFolder();
    if (
      this.isGetFolderGoogleDrive == null ||
      this.isGetFolderGoogleDrive == []
    ) {
      this.isGoogleDrive = false;
    } else {
      this.isGoogleDrive = true;
    }

     await this.get_postcode_mapping();
    
    this.page_loading = false;
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnload);
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  computed: {
    // คำนวณเวลาที่จะแสดงในรูปแบบ "ชั่วโมง:นาที:วินาที"
    formattedElapsedTime() {
      const totalSeconds = Math.floor(this.elapsedTime / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // จัดรูปแบบให้อยู่ในรูปแบบ HH:MM:SS
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
  },
  watch: {
    async show() {
      if (this.show)
        setTimeout(() => {
          const inputElement = document.getElementById("packingCodeAd");
          inputElement.focus();
          this.$vuetify.goTo(this.$refs.packingItem);
        }, 100);
    },

    async orderItem() {
      // console.log(this.orderItem,"orderItem.packages")
    },
  },
  mounted() {
    // ดึงรายการกล้องเมื่อ component ถูกสร้าง
    this.getDevices();
  },

  methods: {
    //ส่วนของ API
    async getCheckOrderStatusBeforePack(OrderMasterID, TrackingNo) {
      return await axios
        .get(
          orderService_dotnet +
            "Order/check-order-status-before-pack?" +
            "StaffBranchID=" +
            localStorage.getItem("Branch_StaffID") +
            "&OrderMasterID=" +
            OrderMasterID +
            "&TrackingNo=" +
            TrackingNo,
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.isStop) {
            this.loading = false;
            return await this.alertStopPack(res.data);
          }
          if (res.data.isConfirm) {
            this.loading = false;
            return await this.alertConfirm(res.data);
          }
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.audioBuzzer.play();
          this.AlertError();
          return false;
        });
    },
    async getOrderMasterForPack(orderID) {
      return await axios
        .get(
          orderService_dotnet +
            "Order/get-order-master-for-pack?OrderMasterID=" +
            orderID,
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getOrderItemPackageForPack(orderID) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-order-package-for-pack",
          { orderID: orderID },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getOrderItemTemp(orderID) {
      return await axios
        .post(
          orderService_dotnet + "Order/get-order-item-temp",
          { orderID: orderID },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getShippingAddressList(shippingAddressID) {
      return await axios
        .post(
          shopService_dotnet + "Shop/get-shipping-address-list",
          { id: shippingAddressID, isAscending: true },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data.shift();
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getShopAddress(shopAddressID) {
      return await axios
        .post(
          shopService_dotnet + "ShopAddress/get-shop-address",
          { id: shopAddressID },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getStockShopByPack() {
      return await axios
        .post(
          stockService_dotnet + "Stock/get-stock-shop-by-pack",
          {
            shopID: this.orderInfo.shopID,
            search: this.packingCodeAdd,
            OrderMasterID: this.orderInfo.id,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async GetCheckInfoNeedForPack(dateUpdated) {
      return await axios
        .post(
          orderService_dotnet + "Order/check-info-need-for-pack",
          {
            shopID: this.currentShopID,
            branchID: localStorage.getItem("Branch_BranchID"),
            updatedTimeStamp: dateUpdated,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async savePackOrder(body) {
      console.log("bodyเข้ามา", body);
      return await axios
        .post(orderService_dotnet + "Order/save-pack-order-v2", body, {
          headers: this.header_token,
        })
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async splitPackage(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/split-package",
          { items: body },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getGoogleDriveFolder() {
      return await axios
        .post(
          generalService_dotnet + "GoogleDrive/get-branch-google-drive-for-pack",
          {
            branchID: localStorage.getItem("Branch_BranchID"),
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data.item;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async updateCreateSubfolder(FolderType, clentJson, FolderDocs) {
      let folder_1 = this.isGetFolderGoogleDrive[0];
      console.log(folder_1);
      axios
        .post(
          generalService_dotnet + "GoogleDrive/update-branch-google-drive",
          {
            branchID: localStorage.getItem("Branch_BranchID"),
            folderID: FolderType == 1 ? folder_1.folderID : FolderDocs.id,
            folderName: FolderType == 1 ? folder_1.folderName : FolderDocs.name,
            clentJson: FolderType == 1 ? clentJson : "",
            FolderType: FolderType,
          }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async upLoadFileGoogleDrive(jsonClent, folderSub, recoredFile) {
      let accessToken = jsonClent.access_token; // แทนที่ด้วย Access Token ที่ดึงมา
      let parentId = folderSub.id; // เปลี่ยนเป็น ID ของโฟลเดอร์ที่ต้องการอัปโหลด
      let fileName = "test01";
      const formData = new FormData();
      console.log("resip[]", formData);
      formData.append("title", "asdasdasd");
      formData.append("file", this.File);
      formData.append(
        "metadata",
        new Blob([JSON.stringify({ name: fileName, parents: [parentId] })], {
          type: "application/json",
        })
      );
      console.log("resip[]", formData);
      // Get an upload session

      let res = axios
        .post(
          "https://www.googleapis.com/upload/drive/v3/files",

          {
            metadata: new Blob(
              [JSON.stringify({ name: fileName, parents: [parentId] })],
              { type: "application/json" }
            ),
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch(async (error) => {
          if (error.response.status == 401) {
            console.log("refreshAccessToken");
            let refreshAccessToken = await this.refreshAccessToken();
            console.log("refreshAccessToken", refreshAccessToken);

            await this.upLoadFileGoogleDrive(
              this.jsonClent,
              this.folderSub[0],
              this.recordedBlob
            );
          }
        });
      console.log("resip[]", res);
    },
    async upLoadFile(RefileName) {
      let dateMomnet = moment(new Date()).format("YYYYMMDD_hhmmss");
      var fileName = ''
      console.log("RefileName",RefileName);
      
        if(fileName) {
          fileName  = RefileName
        }else{
          fileName = this.ChangeReferenceInfo(this.OrderID) + "_" + dateMomnet;

        }
      let body = [
        {
          fileName: fileName,
          status: "กำลังโหลด",
          thumbnail: this.thumbnailImg,
        },
      ];
    
        this.isWaitingUploadGoogleDrive.push(...body);

      

      const parentId = this.folderSub.id; // เปลี่ยนเป็น ID ของโฟลเดอร์ที่ต้องการอัปโหลด
      const formData = new FormData();

      formData.append("file", this.recordedBlob);
      formData.append(
        "metadata",
        new Blob(
          [JSON.stringify({ name: fileName + ".mp4", parents: [parentId] })],
          { type: "application/json" }
        )
      );

      // Get an upload session
      const res = await axios
        .post("https://www.googleapis.com/upload/drive/v3/files", formData, {
          headers: { Authorization: `Bearer ${this.jsonClent.access_token}` },
        })
        .then((response) => {
          let find = this.isWaitingUploadGoogleDrive.find(
            (i) => i.fileName == fileName
          );
          //ปรับสถานะของไฟล์อัพโหลด
          find.status = "สำเร็จ";
          this.countSuccessUpload++;
          console.log("upLoadFile",response.data);
          this.addOrdermasterUrlGoogleDrive(response.data) 
        })
        .catch(async (error) => {
          if (error.response.status == 401 || error.response.status == 404) {
            let remove = this.isWaitingUploadGoogleDrive.findIndex(
              (item) => item.fileName == fileName
            );
            this.isWaitingUploadGoogleDrive.splice(remove, 1);
            // this.isWaitingUploadGoogleDrive =
            //   this.isWaitingUploadGoogleDrive.find(
            //     (item) => !this.shouldRemove(item)
            //   );

            if(error.response.status == 401){
              let refreshAccessToken = await this.refreshAccessToken();
            this.jsonClent.access_token = refreshAccessToken.access_token;
            await this.updateCreateSubfolder(
              1,
              JSON.stringify(this.jsonClent),
              this.folderSub[0]
            );
            }
            if(error.response.status == 404){
              let findFolder = await this.getGoogleDriveFolder();
              let newFolderSub = await this.createFolder(
                  this.jsonClent,
                  findFolder[0].folderID
                );

                
               console.log("newFolderSub",newFolderSub);
               this.updateCreateSubfolder(2,this.jsonClent,newFolderSub.data)

                this.folderSub[0] = newFolderSub.data

               
            }
     
            
            await this.upLoadFile(
              remove
            );
          }
          console.log(error);
        });
    },
    async createShipment(orderMasterID, packageID) {
      console.log("orderMasterID, packageID", orderMasterID, packageID);
      return await axios
        .post(
          logisticService_dotnet + "Logistic/create-shipment-V2",
          {
            orderMasterID: orderMasterID,
            packageID: packageID,
            staffBranchID: localStorage.getItem("Branch_StaffID"),
            isNeedAddress: true,
            isNeedProductList: true,
            isForceNewTrack: false,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (
            res.data.status == true &&
            (res.data.message == null || res.data.message == "")
          ) {
            return await res.data;
          } else {
            // this.AlertError(res.data.message);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getAwb(item) {
      return await axios
        .post(
          ecomService_dotnet + "Order/get-awb-by-packageid",
          {
            packageID: item.packageID,
            staffBranchID: localStorage.getItem("Branch_StaffID"),
            documentType: 1,
            filetype :   item.referenceSourceID==4? 2:1,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_description);
            return await res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async get_postcode_mapping() {
      var postcode_mapping = JSON.parse(
        localStorage.getItem("postcodeMapping_dotnet")
      );
      
      if (postcode_mapping === "undefined") {
        postcode_mapping = null;
      } else if (postcode_mapping == "null") {
        postcode_mapping = null;
      } else if (postcode_mapping == null) {
        postcode_mapping = null;
      } else if (postcode_mapping == "") {
        postcode_mapping = null;
      }
      if (postcode_mapping != null) {
        this.postcode_mapping = postcode_mapping;

      } else {
        await axios
          .post(
            generalService_dotnet + "General/get-postcodemapping",
            {},
            { headers: this.header_token }
          )
          .then((response) => {
            localStorage.setItem(
              "postcodeMapping_dotnet",
              JSON.stringify(response.data)
            );
            
            this.postcode_mapping = response.data;
          });
      }
    },
    async updateEditAddressReceiverOrdermaster(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/update-receiver-order-package",
          {
            orderItemID: this.orderInfo.id,
            shopAddressID: body.id,
            shopID: this.orderInfo.shopID,
            data: {
              id: body.id,
              postcodeMappingID: body.postcodeMappingID,
              name: body.name,
              phone: body.phone,
              address: body.address,
              province: body.province,
              district: body.district,
              subDistrict: body.subDistrict,
              postcode: body.postcode,
            }
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async updateEditAddressSenderOrdermaster(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/update-sender-order-package",
          {
            orderItemID: this.orderInfo.id,
            shopAddressID: body.id,
            shopID: this.orderInfo.shopID,
            data: {
              id: body.id,
              postcodeMappingID: body.postcodeMappingID,
              name: body.name,
              phone: body.phone,
              address: body.address,
              province: body.province,
              district: body.district,
              subDistrict: body.subDistrict,
              postcode: body.postcode,
            }
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getSettingCamera(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-camera-settings",
          {
            ShopID: body.shopID,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async UpdateSettingCamera() {
      return await axios
        .post(
          orderService_dotnet + "Package/update-camera-settings",
          {
            shopID: this.orderInfo.shopID,
            cameraType: this.selectedTypeCamera,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async UploadImgS3(file_data) {
      var from_data = new FormData();
      from_data.append("bucket", "Doc_file");
      from_data.append("file", file_data);
      return await axios
        .post(
          generalService_dotnet + "General/upload-file-with-bucket",
          from_data,
          {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("authorization_token"),
          },
          }
        )
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async addOrdermasterUrlGoogleDrive(body) {
      let orderMasterID = await body.name.split('_')[0]
      console.log(orderMasterID);
      
      return await axios
        .post(orderService_dotnet + "Order/add-ordermaster-photo",   
          {
            id: 0,
            orderMasterID: parseInt(orderMasterID),
            packageNo: 0,
            mediaType: 1,
            url:   "https://drive.google.com/file/d/"+ body.id + "/preview" 
          }, {
          headers: this.header_token,
        })
        .then(async (res) => {
          return res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },

    //ส่วนของ function
    async FullItem(){
      console.log("this.orderItem",this.orderItem);
      this.orderItem.packages.forEach(s => {
        s.items.forEach(item => {
          item.packedQuantity =  item.quantity
          
        });
        
      });
    },
    async addItemPacking(packageItem) {
      var CheckCommand = await this.runCommand(this.packingCodeAdd);

      if (CheckCommand == false) {
        
        let foundItem = null;
        
        let foundItemNotBombBarcode = packageItem.items.find(
          (item) =>
            item.barcode === this.packingCodeAdd.trim() ||
            item.sku === this.packingCodeAdd.trim() 
        );
        let addItem = this.addItemsIncrease;
        // กรณีสินค้าเป็น น้ำเงินx50 ใช้ได้แต่ barcode
        if(!foundItemNotBombBarcode) {
          var bombBarcode = this.packingCodeAdd.toLocaleLowerCase().split("x")
          console.log("bombBarcode",bombBarcode);
          let foundbombBarcode = packageItem.items.find(
          (item) => item.barcode.toLocaleLowerCase() === bombBarcode[0] );
          foundItem  = foundbombBarcode 
          addItem = isNaN(parseInt(bombBarcode[1])) ? foundItem = null : parseInt(bombBarcode[1]);
          
        
          
        }else{
          foundItem = foundItemNotBombBarcode
        }

        if (foundItem) {
          foundItem.packedQuantity += addItem;
          this.addItemsIncrease = 1;
          let PackCost = await this.calculatePackCost(packageItem);
          let packPrice =
            packageItem.selected.length > 0
              ? packageItem.selected[0].packPrice
              : 0;
          this.$set(packageItem, "packPrice", PackCost + packPrice);
          console.log("packageItem", packageItem);
        } else {
          // let checkStock = [];
          // checkStock.message = "ไม่พบสินค้า";
          // this.alertNotFoundStock(checkStock.message);
          let checkStock = await this.getStockShopByPack();	
          if (checkStock.status == "success" && checkStock.item != null) 
          {																																													
          checkStock.item.packedQuantity = 0;																									
          checkStock.item.packedQuantity += this.addItemsIncrease;		
          console.log("packageItem.item",packageItem);
          console.log("checkStock.item",checkStock.item);

          packageItem.items.unshift(await checkStock.item);																									
          this.addItemsIncrease = 1;																									
          } else if (checkStock.status == "error") {																									
          this.alertNotFoundStock(checkStock.error_description);																									
          } else {																									
          this.alertNotFoundStock(checkStock.message);																									
          }


        }
      }

      this.packingCodeAdd = "";
    },
    async runCommand(commade) {
      if(commade.substring(0,3) == "PHB"){
        console.log("commade");
        let BoxName = commade.split('-')[1];
        console.log("BoxName",BoxName);
        // this.btnBoxSize(this.orderItem.packages[this.orderItem.tab]);
        // console.log("this.orderItem.packages[this.orderItem.tab]",this.orderItem.packages[this.orderItem.tab].postboxes);
        var BoxSize = this.orderItem.packages[this.orderItem.tab].postboxes
        var packageItem = this.orderItem.packages[this.orderItem.tab]
        var findBox = BoxSize.find( x=> x.parentId.toString() == BoxName.toString() || x.name.toString() == BoxName.toString())
        // console.log("findBox",findBox);
        // console.log("packageItem.selected",packageItem.selected);
        if(findBox){
          var  body = 
          {
              "item":findBox,
              "value": true
          }
          
          packageItem.selected = [{...findBox}]
          this.selectedBox(body,packageItem)
          console.log("packageItem",packageItem);
          

        }
      }else{
        switch (commade) {
        case "PH-PICTURE":
          this.captureImage();

          return true;

        case "PH-STARTV":
          this.startRec();

          return true;
        case "PH-PAUSEV":
          this.pauseRec();

          return true;
        case "PH-STOPV":
          this.stopRec();

          return true;
        case "PH-SAVE":
          this.btnSubmitSavePack();
          return true;
        case "PH-SAVEPRINT":
          this.btnSubmitAwb();
          return true;
        case "PH-BUBBLE":
          this.btnBoxSize(this.orderItem.packages[this.orderItem.tab]);
          this.orderItem.packages[this.orderItem.tab].bubble = true;

          return true;
        default:
          return false;
      }
      }
     
    },
    async DeleteItemEachPacking(index, packageItem, removeType) {
      console.log(
        "packageItem",
        packageItem,
        "packageItem.items[index].packedQuantity",
        packageItem.items[index].packedQuantity
      );


      if ((await packageItem.items[index].packedQuantity) >= 1) {
        if (removeType == 1) {
          packageItem.items[index].packedQuantity--;

        } else if (removeType == 2) {
          packageItem.items[index].packedQuantity = 0;
        }

        let PackCost = await this.calculatePackCost(packageItem);
        let packPrice =
          (await packageItem.selected.length) > 0
            ? packageItem.selected[0].packPrice
            : 0;
        this.$set(packageItem, "packPrice", PackCost + packPrice);
      }else{
        if(packageItem.items[index].isLock == false){
          packageItem.items.splice( packageItem.items[index],1 );
        }
        
      }
    },
    changeColorHeader(item) {
      
      if (item.isLock) {
        if (item.isBox) {
          return "";
        } else if (item.quantity == null) {
          
          return "red";
        } else if (
          !item.packedQuantity ||
          item.packedQuantity == 0 ||
          item.isBox
        ) {
          return "";
        } else if (item.packedQuantity == item.quantity) {
          return "lightgreen";
        } else if (item.packedQuantity < item.quantity) {
          return "amber";
        } else if (item.packedQuantity > item.quantity) {
          return "orange";
        }
      } else {
        if(!item.quantity && !item.isBox){
          return "red";
        }
        return "";
      }
      return "";
    },
    clearOrder() {
      this.orderInfo = {};
      this.orderDetail = {};
      this.orderItem.item = {};
      this.orderItemTemp = {};
      this.show = false;
      this.OrderID = null;
      this.$refs.OrderID.focus();
    },
    async CheckInfoNeedForPack() {
      let PackSize = JSON.parse(localStorage.getItem("PackageSize")) || [];

      // ค้นหา box size จาก shopID
      let foundShopID = null;
      if (PackSize != null) {
        foundShopID = PackSize.find((item) => {
          return item.id == this.currentShopID;
        });
      }

      let updatedTimeStamp = foundShopID ? foundShopID.updatedTimeStamp : null;

      let PackSizeRes = await this.GetCheckInfoNeedForPack(updatedTimeStamp);

      if (PackSizeRes.status == "success") {
        if (PackSizeRes.shop == null) {
          return foundShopID;
        } else {
          if (foundShopID != null) {
            let index = PackSize.indexOf(foundShopID);
            PackSize.splice(index, 1, PackSizeRes.shop);
          }
          PackSize.push(PackSizeRes.shop);
          localStorage.setItem("PackageSize", JSON.stringify(PackSize));
          return PackSizeRes.shop;
        }
      } else if (PackSizeRes.error_description) {
        this.alertNotFoundStock(PackSizeRes.error_description);
        return false;
      }

      return false;
    },
    async getPostBox(packageItem, obj) {
      let foundExpress = null;

      if (packageItem.uniqueID != null && packageItem.uniqueID != "") {
        foundExpress = obj.shop_Express.find(
          (item) => item.uniqueID == packageItem.uniqueID
        );
      }
      if (foundExpress == null) {
        if (packageItem.expressCompanyID != null) {
          if(this.orderInfo.referenceSourceID == 3 || this.orderInfo.referenceSourceID == 4 || this.orderInfo.referenceSourceID == 11){
            foundExpress = obj.shop_Express.find(
            (item) => item.referenceSourceID == this.orderInfo.referenceSourceID 
          );

          console.log("obj.packageItem.referenceSourceID ", packageItem.referenceSourceID);
          console.log("obj.shop_Express ", obj.shop_Express);
          console.log("เข้าเงือนไข้ ", foundExpress);
          
          }else{
            foundExpress = obj.shop_Express.find(
            (item) => item.expressCompanyID == packageItem.expressCompanyID
          );
          }
   
        } else {
          return null;
        }
      }

      return foundExpress;
    },
    async calculatePackCost(packageItem) {
      if (packageItem.packingChargedType == 1) {
        return packageItem.packPrice;
      } else if (packageItem.packingChargedType == 2) {
        return 0;
      } else if (packageItem.packingChargedType == 3) {
        // หาจำนวนสินค้าทั้งหมด และ แยกกล่องออกจากสินค้า
        let quantityCount = packageItem.items.reduce((sum, item) => {
          return (
            sum +
            (item.isBox
              ? 0
              : item.packedQuantity == null
              ? 0
              : item.packedQuantity)
          );
        }, 0);
        return await this.subCalculatePackCost(
          packageItem.packingPriceSteps,
          quantityCount
        );
      } else if (packageItem.packingChargedType == 4) {
        // กรณี SKU
        const resultCountOrder = packageItem.items.filter((item) => {
          return !item.isBox;
        });
        let countOrder = resultCountOrder.length;
        return await this.subCalculatePackCost(
          packageItem.packingPriceSteps,
          countOrder
        );
      }
    },
    async subCalculatePackCost(packingPriceSteps, quantityCount) {
      let SumPackPrice = 0;
      let quantityLeft = quantityCount;
      let newStep = packingPriceSteps.sort((a, b) => a.start - b.start);

      newStep.forEach((item) => {
        if (quantityLeft > 0) {
          if (item.end == null) {
            SumPackPrice = SumPackPrice + quantityLeft * item.price;
            return SumPackPrice;
          } else {
            let Range = parseInt(item.end) - item.start + 1;
            if (Range > quantityLeft) {
              Range = quantityLeft;
            }
            SumPackPrice = SumPackPrice + Range * item.price;
            quantityLeft = quantityLeft - Range;
          }
        } else {
          return SumPackPrice;
        }
      });

      return SumPackPrice;
    },
    async validateSavePack() {
      console.log("this.orderItem.packages",this.orderItem.packages);
      let obj = [];

      for (const packages of this.orderItem.packages) {

        for (const item of packages.items) {
          //วนหาข้อมูล แพ็คจริง กับ จำนวนต้องแพ็ค
          if (item.packedQuantity !== item.quantity) {
            obj.title = "ไม่สามารถบันทึกได้ พัสดุ # " +packages.packageNo;
            obj.description = "จำนวนสินค้าไม่ถูกต้อง";
            this.alertValidatePack(obj);

            console.log("item.quantity",item.packedQuantity);
         
            // this.alertValidatePack()
            // this.alertNotFoundStock();
            return false;
          }
        }

        if (this.orderInfo.boxSizeExpressCompany.isIgnoreWeight == false) {
          if (packages.weight == null) {
            console.log("packages.weight",packages.weight);
            obj.title = "ไม่สามารถบันทึกได้ พัสดุ # " +packages.packageNo;
            obj.description = "น้ำหนักไม่ถูกต้อง";
            this.alertValidatePack(obj)
            return false;
          }
        }

        if (this.orderInfo.boxSizeExpressCompany.isIgnoreDimension == false) {
          if (
            packages.cubic1 == null ||
            packages.cubic2 == null ||
            packages.cubic3 == null
          ) {
            console.log("packages.cubic1",packages.cubic1);
            console.log("packages.cubic2",packages.cubic2);
            console.log("packages.cubic3",packages.cubic3);
         
            obj.title = "ไม่สามารถบันทึกได้ พัสดุ # " +packages.packageNo;
            obj.description = "ขนาดสินค้าไม่ถูกต้อง";
            this.alertValidatePack(obj)

            return false;
          }
        }
        console.log("this.orderInfo.referenceSourceID",this.orderInfo.referenceSourceID);
        
        if
        (this.orderInfo.referenceSourceID == 3 ||
        this.orderInfo.referenceSourceID == 4 ||
        this.orderInfo.referenceSourceID == 11)
        {
          console.log("เข้ามาthis.orderInfo.referenceSourceID");
          
          
        }else{
          if(!packages.deliveryPrice){
            console.log("packages.deliveryPrice",packages.deliveryPrice);
            console.log("packages.packageNo",packages.packageNo);

            obj.title = "ไม่สามารถบันทึกได้ พัสดุ # " +packages.packageNo;
            obj.description = "ค่าส่งไม่ถูกต้อง";
            this.alertValidatePack(obj)

            return false;
          }
        }
        
      }
      return true;
    },
    async setBodyForPack() {
      let body = {};
      let packageList = [];
      let photoListObj = [];
      for (let obj of this.orderItem.packages) 
      {
        var parts = obj.expressUniqueID.split("_");
        //isStockShortage แปลงจากnull
        obj.items.forEach((x) => {
          if (x.isStockShortage == null) {
            x.isStockShortage = false;
          }
        });

        packageList.push({
          id: obj.packageID,
          packageNo: obj.packageNo,
          expressCompanyID: parts[2],
          packedByStaffBranchID: parseInt(
            localStorage.getItem("Branch_StaffID")
          ),
          weight: obj.weight,
          cubic1: obj.cubic1,
          cubic2: obj.cubic2,
          cubic3: obj.cubic3,
          boxName: obj.boxName,
          codAmount: obj.codAmount,
          codCharged: obj.codCharged,
          codPercent: obj.codPercent,
          branchBoxCost: obj.boxCostPrice,
          deliveryPrice: obj.deliveryPrice,
          boxPrice: obj.boxPrice,
          packPrice: obj.packPrice,
          remotePrice: obj.remotePrice,
          bubblePrice: obj.bubblePrice,
          otherServicePrice: obj.otherServicePrice,
          vrichTransactionFee: obj.vrichTransactionFee,
          discount: obj.discount,
          branchExpressID: parts[0],
          shopExpressID: parts[1],
          productList: obj.items,
        });
        
        if(obj.photoList.length > 0)
        {
            
          for (let item of obj.photoList) {

            if(item.created == null){
              const img =await  this.base64ToImg(item.pic);
              let res =await  this.UploadImgS3(img);
        
            
              if( res.status ==  "success")
              {
                item.pic =await res.file_path
                photoListObj.push(item)
              } 
            }else{
              // item.packageNo แก้เซฟ เป็นnull
              // item.packageNo = 0;
              photoListObj.push(item)
            }
         
          }
        }
      }
       
  
     
      
     
     console.log("photoListObj",photoListObj);
     
  
      

      body.orderMasterID = this.orderInfo.id;
      body.packageList =  packageList;
      body.shopBankAccountID = null;
      body.photoList = photoListObj.length > 0 ?  photoListObj : null;
      // [
      //   {
      //     "id": 0,
      //     "orderMasterID": 0, ตัวอย่างถ้ามีค่า
      //     "url": "https://packhaistorage.s3-ap-southeast-1.amazonaws.com//2024-10/3152234d20241029190643.jpg"
      //   }
      // ],

      return await body;
    },
    async changedExpress(selectedValue, packageItem) {
      let itemValue = { uniqueID: selectedValue };
      
      let item = await this.getPostBox(
        itemValue,
        this.orderInfo.boxSizeExpressCompany
      );
      
      if (item) {
        console.log(packageItem);
        packageItem.expressCompanyID = item.expressCompanyID;
        packageItem.branchExpressID = item.branchExpressID;
        packageItem.shopExpressID = item.shopExpressID;
        packageItem.uniqueID = item.uniqueID;
        packageItem.packingChargedType = item.packingChargedType;
        packageItem.postboxes = item.postboxes;
        packageItem.packingPriceSteps = item.packingPriceSteps;
        packageItem.selected = [];
        if(this.orderInfo.isCOD == true){
          packageItem.codPercent =  item.codPercent 
          packageItem.codCharged =  (this.orderInfo.codAmount * (item.codPercent / 100)).toFixed(1);
        }
        packageItem.remotePrice = await this.getRemotePriceReceiverAddress(this.orderInfo.receiverAddress.postcodeMappingID,item.expressCompanyID);

        let box = item.postboxes.find(
          (item) => item.name === packageItem.boxName
        );
        packageItem.selected = box ? [box] : [];
      }
      this.calculatePackCost(packageItem);
    },
    async findProvinceGroupID(packageItem) {
      // ฟังก์ชันหาค่า provinceGroupID

      var branchExpressID_BY_uniqueID = this.orderInfo.expressChannel.find(
        (x) => x.uniqueID == packageItem.uniqueID
      );
      var provinceGroups = branchExpressID_BY_uniqueID.provinceGroups;
      var targetID = this.orderInfo.receiverAddress.provinceId;
      var findProvinceGroupID = null;
      for (let group of provinceGroups) {
        if (group.provinceIDList.includes(targetID)) {
          findProvinceGroupID = group.provinceGroupID;
        }
      }

      var filterDeliveryPrices = null;
      if (findProvinceGroupID == null) {
        return null;
      } else {
        filterDeliveryPrices =
          branchExpressID_BY_uniqueID.deliveryPrices.filter(
            (p) => p.provinceGroupID == findProvinceGroupID
          );
        return filterDeliveryPrices;
      }
    },
    async filterDeliveryPrices(packageItem) {
      console.log("packageItem", packageItem);

      var DeliveryPrices = await this.findProvinceGroupID(packageItem);
      this.calculateDeliveryPrices(packageItem, DeliveryPrices);
    },
    async calculateDeliveryPrices(packageItem, deliveryGroup) {
      if(this.orderInfo.referenceSourceID != 3 ||
        this.orderInfo.referenceSourceID != 4 ||
        this.orderInfo.referenceSourceID != 11)
        {
          if (
        packageItem.cubic1 &&
        packageItem.cubic2 &&
        packageItem.cubic3 &&
        packageItem.weight
      ) {
        //หาน้ำหนักจากโดยปริมาตร
        var volumeweight =
          (packageItem.cubic1 * packageItem.cubic2 * packageItem.cubic3) / 6000;
        var weightCeil =
          Math.ceil(packageItem.weight) >= Math.ceil(volumeweight)
            ? Math.ceil(packageItem.weight)
            : Math.ceil(volumeweight);

        var deliveryPrices = deliveryGroup.find((d) => d.weight == weightCeil);

        if (deliveryPrices) {
          this.$set(packageItem, "deliveryPrice", deliveryPrices.price);
        } else {
          this.$set(packageItem, "deliveryPrice", null);
        }
      }

      }else{
        this.$set(packageItem, "deliveryPrice", null);

      }

    
    },
    async selectedBox(event, packageItem) {
      console.log("event",event);
      
      if (event.value) {
        this.$set(packageItem, "boxName", event.item.name);
        this.$set(packageItem, "boxPrice", event.item.packAndBoxPrice);
        this.$set(
          packageItem,
          "bubblePrice",
          packageItem.bubble ? event.item.bubblePrice : 0
        );
        this.$set(packageItem, "cubic1", event.item.cubic1);
        this.$set(packageItem, "cubic2", event.item.cubic2);
        this.$set(packageItem, "cubic3", event.item.cubic3);
        this.$set(packageItem, "boxCostPrice", event.item.boxCostPrice);

        // console.log("B00219777",event.item,"packageItem",packageItem)
        // console.log("this.orderInfo.boxSizeExpressCompany",this.orderInfo.expressChannel);
        console.log("tpackageItem", packageItem);

        var deliveryPrices = await this.findProvinceGroupID(packageItem);
        this.calculateDeliveryPrices(packageItem, deliveryPrices);

        let PackCost = await this.calculatePackCost(packageItem);
        this.$set(packageItem, "packPrice", PackCost + event.item.packPrice);
      }
    },
    async setBodyForSplitPackage(item) {
      let items = item.map((item) => ({
        orderItemID: item.orderItemID,
        quantity: parseInt(item.packedQuantity),
      }));
      return items;
    },
    ShowSplitMenu(item) {
      let cloneItem = [...item];
      let CheckOverQuntity = cloneItem.filter(
        (obj) =>
          (item.length > 1 &&
            obj.packedQuantity > 0 &&
            obj.packedQuantity <= obj.quantity) ||
          (item.length == 1 &&
            obj.packedQuantity < obj.quantity &&
            obj.quantity >= 2 &&
            obj.packedQuantity >= 1)
      );
      if (CheckOverQuntity.length > 0) {
        return true;
      }
      return false;
    },
    async UploadGoogleDrive() {
      //สำหรับบังคับห้ามออก
      window.removeEventListener("beforeunload", this.beforeUnload);
      window.removeEventListener("keydown", this.handleKeyDown);

      let resRefreshAccessToken = [];
      let newFolderSub = [];
      let findFolder = await this.getGoogleDriveFolder();
      let folderSub = findFolder.filter((f) => f.folderType === 2);
      this.jsonClent = await JSON.parse(findFolder[0].clentJson);
      let matchingItems = folderSub.filter((item) =>
        this.isCurrentDate(item.folderName)
      );
      if (matchingItems == undefined || matchingItems.length <= 0) {
        let resCreate = await this.createFolder(
          this.jsonClent,
          findFolder[0].folderID
        );
        if (resCreate == 401) {
          resRefreshAccessToken = await this.refreshAccessToken();
          this.jsonClent.access_token = resRefreshAccessToken.access_token;
          newFolderSub = await this.createFolder(
            this.jsonClent,
            findFolder[0].folderID
          );
        }
      } else {
        this.folderSub = matchingItems;
        this.folderSub.id = matchingItems[matchingItems.length-1].folderID;
      }
      // if(this.folderSub != null){
      //   matchingItems = this.folderSub;
      //   this.folderSub.id = this.folderSub.folderID
      // }
      this.upLoadFile();
    },
    async generateThumbnail() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageUrl = canvas.toDataURL("image/png");
      this.thumbnailImg = imageUrl;
    },
    async convertBytesToPDF(byteArrays) {
      // สร้างเอกสาร PDF ใหม่
      var pdfDoc = await PDFDocument.create();

      for (let i = 0; i < byteArrays.length; i++) {
        var byteArray = byteArrays[i];
        var buffer = Buffer.from(byteArray, "base64");
        var uint8Array = new Uint8Array(buffer);

        // โหลดเอกสาร PDF
        var pdfDocToAdd = await PDFDocument.load(uint8Array);

        // คัดลอกหน้าจากเอกสาร PDF ที่โหลดมา
        var copiedPages = await pdfDoc.copyPages(
          pdfDocToAdd,
          pdfDocToAdd.getPageIndices()
        );

        // แทรกทุกหน้าลงในเอกสารใหม่
        for (var copiedPage of copiedPages) {
          pdfDoc.addPage(copiedPage);
        }
      }
      // แปลง PDF เป็น bytes
      var pdfBytes = await pdfDoc.save();

      //   // บันทึกเอกสาร PDF
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(blob);

      const newWindow = window.open(pdfUrl);

      newWindow.print();
    },
    async convertBase64HtmlToArrayBuffer(base64Html) {
      const htmlString = decodeURIComponent(escape(atob(base64Html)));
      let iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);
      let iframedoc = iframe.contentDocument || iframe?.contentWindow?.document;
      if (!iframedoc) return;
      iframedoc.body.innerHTML = htmlString;
      let canvas = await html2canvas(iframedoc.body, {});
      let imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        format: "a4",
        unit: "mm",
      });
      doc.addImage(imgData, "PNG", 0, 0, 210, 297);
      document.body.removeChild(iframe);
      return doc.output("arraybuffer");
    },
    async CreateAwb() {
      let dataOUT = [];

      for(let item of this.orderItem.packages){
           
      
      if (
        this.orderInfo.referenceSourceID == 3 ||
        this.orderInfo.referenceSourceID == 4 ||
        this.orderInfo.referenceSourceID == 11
      ) {
        let data = await this.getAwb(item);
        if (data.bytes != null) {
          dataOUT.push(data.bytes);
        } else if (data.base64HTML != null) {
          let bytes = await this.convertBase64HtmlToArrayBuffer(
            data.base64HTML
          );
          dataOUT.push(bytes);
        }
      } else {
        let data = await this.createShipment(
          item.orderMasterID,
          item.packageID
        );
        if (
          data.status == true &&
          (data.message == null || data.message == "")
        ) {
          await  dataOUT.push(data);
          console.log("createShipment",data);
          
        } else {
          this.alertStop();
        }
      }

    } 


      if (
        this.orderInfo.referenceSourceID == 3 ||
        this.orderInfo.referenceSourceID == 4 ||
        this.orderInfo.referenceSourceID == 11
      ) {
        if (dataOUT.length > 0) {
          this.convertBytesToPDF(dataOUT);
        }
      } else {
        console.log("dataOUT",dataOUT);
        
        var key = Math.floor(Math.random() * 1000000 + 1).toString();
        localStorage.setItem("printawb", JSON.stringify(dataOUT));
        sessionStorage.setItem("encodedQueryKey_printAws", key);
        window.open(`/print-awb?key=${key}`);
      }
    },
    async OpenCameraPopup() {
      // this.selectedCameraPopup.dialog = true;
      this.$forceUpdate();
    },
    async calculateCodPercent(packageItem) {
      var Percent = packageItem.codPercent;
      var Amount = this.orderInfo.codAmount;
      var Charged = 0;
      Charged = (Amount * (Percent / 100)).toFixed(1);

      this.orderItem.packages.forEach((item) => (item.codCharged = Charged));
    },
    async calculateCodCharged(packageItem) {
      var Charged = packageItem.codCharged;
      var Amount = this.orderInfo.codAmount;
      var Percent = 0;
      Percent = ((Charged / Amount) * 100).toFixed(1);
      this.orderItem.packages.forEach((item) => (item.codPercent = Percent));
    },
    async getRemotePriceReceiverAddress(orderInfo,expressItem)
    {
    
       let findPostcode =  this.postcode_mapping.find(
                  (item) => item.Id === orderInfo
                );
       if(findPostcode){
        let findRemoteExpressItem = await  findPostcode.RemotePrice.find( 
                (express) => express.ExpressCompanyID === expressItem
               )
            if(findRemoteExpressItem){
              return findRemoteExpressItem.Price;
            }else{
              return null
            }
       }else{
        return null 
       }
                

    },
    async UploadS3(base64){

      const img = await this.base64ToImg(base64);
      return await this.UploadImgS3(img);
      
      // console.log("UploadS3data",data);
      
      
    },
    async base64ToImg(base64) {
      
      const base64replace = base64.replace(/^data:image\/\w+;base64,/, "");
      const byteCharacters = atob(base64replace);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    //สร้าง Folder ใหม่
    async createFolder(jsonClent, findFolder) {
      const accessToken = await jsonClent.access_token; // แทนที่ด้วย Access Token ที่ดึงมา
      const parentId = findFolder; // เปลี่ยนเป็น ID ของโฟลเดอร์ที่ต้องการอัปโหลด

      var date = moment(new Date()).format("YYYY_MM_DD");
      return await axios
        .post(
          "https://www.googleapis.com/drive/v3/files",
          {
            mimeType: "application/vnd.google-apps.folder",
            name: date,
            parents: [parentId],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.updateCreateSubfolder(2, this.jsonClent, res.data);
          return (this.folderSub = res.data);
        })
        .catch(async (error) => {
          console.log(" error", error.response.status);
          return error.response.status;
        });
    },
    async refreshAccessToken() {
      const data = new URLSearchParams();
      data.append("refresh_token", this.jsonClent.refresh_token);
      data.append("client_secret", "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");
      data.append("grant_type", "refresh_token");
      data.append(
        "client_id",
        "73555754893-5sjbfn01j3lhjijla4jgtmd361dq4k6h.apps.googleusercontent.com"
      );
      return axios
        .post(
          "https://oauth2.googleapis.com/token?",
          data.toString(), // Convert URLSearchParams to string
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },
    async isCurrentDate(dateString) {
      const dateRegex =
        /(\d{4})[_/](\d{2})[_/](\d{2})|(\d{2})[_/](\d{2})[_/](\d{4})/;

      const match = dateString.match(dateRegex);
      if (match) {
        let year, month, day;

        if (match[1] && match[2] && match[3]) {
          // YYYY_MM_DD or YYYY/MM/DD
          year = match[1];
          month = match[2];
          day = match[3];
        } else if (match[4] && match[5] && match[6]) {
          // DD/MM/YYYY or DD_MM_YYYY
          day = match[4];
          month = match[5];
          year = match[6];
        }

        const dateFromItem = new Date(year, month - 1, day); // JavaScript month is 0-indexed
        const today = new Date();

        return dateFromItem.toDateString() === today.toDateString();
      }
      return false;
    },
    //ส่วนของ Btn
    async btnSearchOrderID() {
      this.currentShopID = null;
      const OrderID = this.OrderID.trim();
      this.orderInfo = {};
      this.orderDetail = {};
      this.orderItem.item = {};
      this.orderItemTemp = {};
      this.show = false;
      clearInterval(this.timer);
      // this.stopRec()
      if (OrderID) {
        this.loading = true;
        try {
          var ID = this.ChangeReferenceInfo(OrderID);
          var OrderMasterID = parseInt(ID) ? parseInt(ID) : 0;
          var TrackingNo = ID;
          this.stopSaveGoogleDrive = false

          //เช็ค สถานะ PA ว่าสามารถแพ็คสินค้าได้ไหม
          const orderMasterRes = await this.getCheckOrderStatusBeforePack(
            OrderMasterID,
            TrackingNo
          );
          if (orderMasterRes) {
            this.currentShopID = orderMasterRes.shopID;
            //เรียก API ดึงรายละเอียดลูกค้า
            var resOrdermasterForPack_job = await this.getOrderMasterForPack(
              orderMasterRes.orderMasterID
            );
            var item_job = this.getOrderItemPackageForPack(
              orderMasterRes.orderMasterID
            );
            var itemTemp_job = this.getOrderItemTemp(
              orderMasterRes.orderMasterID
            );
            let boxSizeExpressCompany_job = this.CheckInfoNeedForPack();
            

            var resOrdermasterForPack = await resOrdermasterForPack_job;
            var item = await item_job;
            var itemTemp = await itemTemp_job;
            let boxSizeExpressCompany = await boxSizeExpressCompany_job;
            
            this.orderInfo = await resOrdermasterForPack.data;

            //สำหรับเช็คว่าค่าแพ็คอิงตาม SHOP

            this.orderInfo.expressChannel = boxSizeExpressCompany.shop_Express;
            this.orderInfo.expressChannelToShow =
              this.orderInfo.expressChannel.filter(
                (item) =>
                  item.referenceSourceID == null ||
                  !this.platformReferenceSourceID.includes(
                    item.referenceSourceID
                  )
              );
              
            this.orderInfo.boxSizeExpressCompany = boxSizeExpressCompany;
            
            //set กล่องเข้าไปในแต่ packages
            for (var packageItem of item.packages) {
              if (packageItem.expressCompanyID != null) {
                //ดึงค่ากล่อง

                console.log("boxSizeExpressCompany",boxSizeExpressCompany);
                
                let expressItem = await this.getPostBox(
                  packageItem,
                  boxSizeExpressCompany
                );

                console.log("packageItem",packageItem);
                
  
                
                // console.log("this.orderInfo",this.orderInfo.receiverAddress.postcodeMappingID); //ได้เขตที่อยู่
                console.log("expressItem",expressItem);  //ได้ expressItemcompany
              
                packageItem.packingPriceSteps = expressItem.packingPriceSteps;
                packageItem.packingChargedType = expressItem.packingChargedType;
                packageItem.postboxes = expressItem.postboxes;
                packageItem.expressCompanyID = expressItem.expressCompanyID;
                packageItem.branchExpressID = expressItem.branchExpressID;
                packageItem.shopExpressID = expressItem.shopExpressID;
                packageItem.uniqueID = expressItem.uniqueID;
                if(this.orderInfo.receiverAddress ){
                  packageItem.remotePrice = await this.getRemotePriceReceiverAddress(this.orderInfo.receiverAddress.postcodeMappingID,expressItem.expressCompanyID);

                }
               
                //หาว่ามีค่ากล่องที่เลือกไหม
                let box = expressItem.postboxes.find(
                  (item) => item.name === packageItem.boxName
                );
                packageItem.selected = box ? [box] : [];

                //ใส่ cod
                if (this.orderInfo.isCOD == true) {
                  packageItem.codPercent = expressItem.codPercent ;
                  packageItem.codAmount = this.orderInfo.codAmount;
                  packageItem.codCharged = (
                    this.orderInfo.codAmount * (expressItem.codPercent / 100 )
                  ).toFixed(1);
                }
              } else {
                packageItem.postboxes = [];
                packageItem.packingPriceSteps = null;
                packageItem.packingChargedType = null;
                packageItem.selectBox = [];
              }
            }

            this.orderItem.packages = item.packages;

            this.orderItemTemp = itemTemp;
            //เรียก API ที่อยู่ผู้ส่ง

            this.OrderID = OrderID;
            this.show = true;
            this.UpDownloadingGoogleDrive = true;
            this.loading = false;
          } else {
            this.$refs.OrderID.focus();
          }

          if (this.isGoogleDrive == true) {
            let getSettingCamera_job = await this.getSettingCamera(
              await resOrdermasterForPack_job.data
            );

            if ((await getSettingCamera_job.data) != null) {
              console.log(getSettingCamera_job.data.cameraType);

              this.selectedTypeCamera = getSettingCamera_job.data.cameraType;
            }
            
            if(this.orderItem.packages.find( l => l.photoList.length > 0)){
              this.stopCamera();
              this.stopSaveGoogleDrive = true;
            }else{
              await this.startRec();
            }

            

            // this.orderItem.packages.forEach((item) => {
            //   item.photoList = [];
            // });
          }

          let isOrderIDSearchComplete = false;
          // console.log("  this.orderInfo",  this.orderInfo);
          // console.log("  this.this.orderDetail",  this.orderDetail);
        } catch (e) {
          console.log(e, "เกิดปัญหา");
          this.loading = false;
          this.show = false;
          this.audioBuzzer.play();
          this.AlertError();
        }
      }
    },
    async btnSearchOrderIDSplitPackage(resSplit,packagesItemTemp) {
   
      const OrderID = this.OrderID.trim();
      
  
      this.orderItem.item = {};
 
      this.show = false;
      clearInterval(this.timer);
      // this.stopRec()
      if (OrderID) {
        this.loading = true;
        try {
          var ID = this.ChangeReferenceInfo(OrderID);
          var OrderMasterID = parseInt(ID) ? parseInt(ID) : 0;
          var TrackingNo = ID;


          let boxSizeExpressCompany_job = this.CheckInfoNeedForPack();

          let boxSizeExpressCompany = await boxSizeExpressCompany_job;


          //เช็ค สถานะ PA ว่าสามารถแพ็คสินค้าได้ไหม
          const orderMasterRes = await this.getCheckOrderStatusBeforePack(
            OrderMasterID,
            TrackingNo
          );
          if (orderMasterRes) {
            this.currentShopID = orderMasterRes.shopID;
            //เรียก API ดึงรายละเอียดลูกค้า
        

            //set กล่องเข้าไปในแต่ packages
            for (var packageItem of resSplit.packages) {
              if (packageItem.expressCompanyID != null) {
              
                
                //ดึงค่ากล่อง
                let expressItem = await this.getPostBox(
                  packageItem,
                  boxSizeExpressCompany
                );
             
                let tempItems =await packagesItemTemp.find(i => i.packageNo == packageItem.packageNo )
               
                // Object.assign(packageItem, tempItems);
                // packageItem.items = packageItem.items;

                for(var item of packageItem.items)
                {
                  
                  if(packageItem.packageNo == this.SplitPackageNo)
                  {
                    item.packedQuantity = item.quantity;
                  }
                  else if(packageItem.packageNo == (this.SplitPackageNo + 1)){  
                    item.packedQuantity = null;
                  }else{
                    if(tempItems != undefined){
                      let setItemQuantity =await tempItems.items.find(s => s.orderItemID == item.orderItemID )
                      if(setItemQuantity != undefined){
                      item.packedQuantity = setItemQuantity.packedQuantity
                    }
                    }
                  
                  }
                  
                }
                
                // console.log("this.orderInfo",this.orderInfo.receiverAddress.postcodeMappingID); //ได้เขตที่อยู่
                // console.log("expressItem",expressItem.expressCompanyID);  //ได้ expressItemcompany
                if(tempItems){
                 packageItem.packingPriceSteps = expressItem.packingPriceSteps;
                packageItem.packingChargedType = expressItem.packingChargedType;
                packageItem.postboxes = expressItem.postboxes;
                packageItem.expressCompanyID = expressItem.expressCompanyID;
                packageItem.branchExpressID = expressItem.branchExpressID;
                packageItem.shopExpressID = expressItem.shopExpressID;
                packageItem.uniqueID = expressItem.uniqueID;
                packageItem.remotePrice = await this.getRemotePriceReceiverAddress(this.orderInfo.receiverAddress.postcodeMappingID,expressItem.expressCompanyID);
               
                //หาว่ามีค่ากล่องที่เลือกไหม
                let box = expressItem.postboxes.find(
                  (item) => item.name === packageItem.boxName
                );
                packageItem.selected = box ? [box] : [];
                // this.orderItem.tab = this.orderItem.tab + 1
                }
                else{
                 packageItem.packingPriceSteps = expressItem.packingPriceSteps;
                packageItem.packingChargedType = expressItem.packingChargedType;
                packageItem.postboxes = expressItem.postboxes;
                packageItem.expressCompanyID = expressItem.expressCompanyID;
                packageItem.branchExpressID = expressItem.branchExpressID;
                packageItem.shopExpressID = expressItem.shopExpressID;
                packageItem.uniqueID = expressItem.uniqueID;
                packageItem.remotePrice = await this.getRemotePriceReceiverAddress(this.orderInfo.receiverAddress.postcodeMappingID,expressItem.expressCompanyID);
               
                //หาว่ามีค่ากล่องที่เลือกไหม
                let box = expressItem.postboxes.find(
                  (item) => item.name === packageItem.boxName
                );
                packageItem.selected = box ? [box] : [];
                // this.orderItem.tab = this.orderItem.tab + 1
                }
              
             
              } else {
                packageItem.postboxes = [];
                packageItem.packingPriceSteps = null;
                packageItem.packingChargedType = null;
                packageItem.selectBox = [];
              }
            }

            this.orderItem.packages = resSplit.packages;
 


            this.OrderID = OrderID;
            this.show = true;
            this.loading = false;
          } else {
            this.$refs.OrderID.focus();
          }

   

          let isOrderIDSearchComplete = false;
          // console.log("  this.orderInfo",  this.orderInfo);
          // console.log("  this.this.orderDetail",  this.orderDetail);
        } catch (e) {
          console.log(e, "เกิดปัญหา");
          this.loading = false;
          this.show = false;
          this.audioBuzzer.play();
          this.AlertError();
        }
      }
    },
    async btnBoxSize(packageItem) {
      console.log("packageItem", packageItem);

      this.$set(
        packageItem,
        "boxName",
        !packageItem.boxName ? 0 : packageItem.boxName
      );
      this.$set(
        packageItem,
        "boxPrice",
        !packageItem.boxPrice ? 0 : packageItem.boxPrice
      );
      this.$set(
        packageItem,
        "cubic1",
        !packageItem.cubic1 ? 0 : packageItem.cubic1
      );
      this.$set(
        packageItem,
        "cubic2",
        !packageItem.cubic2 ? 0 : packageItem.cubic2
      );
      this.$set(
        packageItem,
        "cubic3",
        !packageItem.cubic3 ? 0 : packageItem.cubic3
      );
      if (packageItem.bubble) {
        this.$set(
          packageItem,
          "bubblePrice",
          packageItem.selected.length > 0
            ? packageItem.selected[0].bubblePrice
            : 0
        );
      } else {
        this.$set(packageItem, "bubblePrice", 0);
      }
    },
    async btnSubmitSavePack() {
      this.loading = true;
      let body = [];
      try {
        
        if (await this.validateSavePack()) {
          body = await this.setBodyForPack();
          console.log("saveforpack", body);
          console.log("this.recorder", this.recorder);

            if(( this.isGoogleDrive && this.selectedTypeCamera == 1)   &&  this.stopSaveGoogleDrive == false )
            {
          
              console.log("ทำงานในนี้recorder");
              
              await this.generateThumbnail()
              await this.stopRec()
            }
              this.savePackOrder(body);
            if(( this.isGoogleDrive && this.selectedTypeCamera == 1)   && this.stopSaveGoogleDrive == false )
            {
              console.log("ทำงานในนี้UploadGoogleDrive");

            await this.UploadGoogleDrive()
            this.UpDownloadingGoogleDrive = true
            }
            this.loading = false;
            this.clearOrder();
           }else{
            // this.alertStop();
            this.loading = false;
        }
      } catch (error) {
        console.log("error",error);
        
        this.alertStop();
        this.loading = false;
      }
    },
    async btnSubmitAwb() {
      this.loading = true;
      let body = [];
      try {
        if (await this.validateSavePack()) {
          body = await this.setBodyForPack();
          if (this.isGoogleDrive && this.selectedTypeCamera == 1&& this.stopSaveGoogleDrive == false ) {
            await this.generateThumbnail();
            await this.stopRec();
          }
          await this.savePackOrder(body);
          if (this.isGoogleDrive && this.selectedTypeCamera == 1&& this.stopSaveGoogleDrive == false ) {
            await this.UploadGoogleDrive();
          }
          this.loading = false;
          this.CreateAwb();
          this.clearOrder();
        } else {
          // this.alertStop();
          this.loading = false;
        }
      } catch (error) {
        this.alertStop();
        this.loading = false;
      }
    },
    async btnSplitPackage(item,packageNo) {
      let cloneItem = [...item];
      console.log("packageNo",packageNo);
      this.SplitPackageNo = packageNo;
      
      let CheckOverQuntity = cloneItem.filter(
        (obj) =>
          (item.length > 1 &&
            obj.packedQuantity > 0 &&
            obj.packedQuantity <= obj.quantity) ||
          (item.length == 1 &&
            obj.packedQuantity <= obj.quantity &&
            obj.quantity >= 2 &&
            obj.packedQuantity >= 1)
      );

      this.splitPackageDataSet.dataSet = [...CheckOverQuntity];

      this.splitPackagePopup.dialog = true;
    },
    async btnSubmitSplitPackage() {
      let item = this.splitPackageDataSet.dataSet;

      let itemTep = this.orderItem.packages
      
      if (item.length > 0) {
        let bodyAPI = await this.setBodyForSplitPackage(item);
        if (
          (await this.alertConfirmText("ต้องการแยกกล่องใช้ หรือ ไม่ ")) == true
        ) {
          let resSplit = await this.splitPackage(await bodyAPI);
          
          console.log("resSplit",resSplit,itemTep);
          
          localStorage.setItem(
            "setSplitPackage",
            JSON.stringify(this.orderItem.packages)
          );

         
          
          await this.btnSearchOrderIDSplitPackage(await resSplit,itemTep)


          this.splitPackagePopup.dialog = false;
        }
      }
    },
    async btnBack() {
      if (this.isGoogleDrive == true) {
        this.stopRec();
      }
      this.$router.push("/dashboard-statistics");
    },
    async btnEditAddress(type) {
      if (type == "receiver") {
        this.editAddressDataSet = { ...this.orderInfo.receiverAddress }; 
        this.editAddressPopup.typeAddress = type;

        console.log("receiver", this.editAddressDataSet);
      } else if (type == "sender") {
        this.editAddressDataSet = { ...this.orderInfo.senderAddress };
        this.editAddressPopup.typeAddress = type;

        console.log("sender", this.editAddressDataSet);
      }

      this.editAddressPopup.dialog = true;
    },
    async btnSummitEditAddress() {
      //เอา ID postcodeMappingID มาหาใส่ค่าไปใหม่
      let findPostcode =  this.postcode_mapping.find(
                  (item) => item.Id === this.editAddressDataSet.postcodeMappingID
                );

     this.editAddressPopup.district =  findPostcode.District
     this.editAddressPopup.subDistrict =  findPostcode.SubDistrict
     this.editAddressPopup.postcode =  findPostcode.Postcode
     this.editAddressPopup.province =  findPostcode.Province
     
      
      if (this.editAddressPopup.typeAddress == "receiver") {
      
       
       console.log(" this.editAddressDataSetreceiver", this.editAddressDataSet);
      
       var resReceiver =  await this.updateEditAddressReceiverOrdermaster(this.editAddressDataSet);
    
       console.log("res_receiver",resReceiver);

        this.orderInfo.receiverAddress =await resReceiver.data
        
        //คำนวณค่าพื้นที่ห่างไกล
        this.orderItem.packages.forEach(
          (item) =>  item.remotePrice =
         this.getRemotePriceReceiverAddress(this.orderInfo.receiverAddress.postcodeMappingID,item.expressCompanyID)
        );

      } else if (this.editAddressPopup.typeAddress == "sender") {

        console.log(" this.editAddressDataSetsender", this.editAddressDataSet);
        
        var resSender = await this.updateEditAddressSenderOrdermaster( this.editAddressDataSet );
         
        console.log("res_sender",resSender);
        // 
         this.orderInfo.senderAddress = await resSender.data
      }

      this.editAddressPopup.dialog = false;
    },
    async btnDeleteImagesCaptured(index) {
      this.orderItem.packages[this.orderItem.tab].photoList.splice(
        index,
        1
      );
      this.$forceUpdate();
    },
    async btnReloadOpenCamera() {
      Swal.fire({
        icon: "question",
        title: "จะต้องเริ่มทำรายการแพ็คใหม่ตั้งแต่ต้น ",
        text: "ต้องการเปิดกล้อง ใช่ หรือ ไม่",
        confirmButtonText: "ตกลง",
        cancelButtonText: "ไม่ใช้",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          window.location.reload();
        }
      });
    },
    async btnSaveCameratype() {
      
      if(this.orderInfo.shopID){
        this.UpdateSettingCamera();
        if(this.selectedTypeCamera == 1){
          this.startRec();
        }

      }
       
    },

    //ส่วนของ Alert
    async alertConfirm(obj) {
      return Swal.fire({
        icon: "question",
        text: obj.description,
        confirmButtonText: "ใช้",
        cancelButtonText: "ไม่ใช้",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          return obj;
        } else {
          return false;
        }
      });
    },
    async alertStopPack(obj) {
      this.audioBuzzer.play();
      return Swal.fire({
        icon: "warning",
        text: obj.description,
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    },
    async alertValidatePack(obj) {
      this.audioBuzzer.play();
      return Swal.fire({
        icon: "warning",
        title: obj.title,
        text: obj.description,
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    },
    async alertNotFoundStock(message) {
      this.audioBuzzer.play();
      return Swal.fire({
        icon: "warning",
        text: message,
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        return false;
      });
    },
    async alertConfirmText(description) {
      return Swal.fire({
        icon: "question",
        text: description,
        confirmButtonText: "ใช้",
        cancelButtonText: "ไม่ใช้",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          return true;
        } else {
          return false;
        }
      });
    },
    async alertStop() {
      Swal.fire({
        // title: "The Internet?",
        text: "ไม่สามารถทำรายการได้",
        icon: "error",
      });
    },
    //ส่วนของอัดวีดีโอ
    async startRec() {
      if (this.isPaused) {
        // บันทึกต่อ
        this.isRecording = true;
        this.isPaused = false;
        this.stopSaveGoogleDrive == false

        // เริ่มตัวจับเวลาอีกครั้ง
        this.timer = setInterval(() => {
          this.elapsedTime += 1000;
        }, 1000);

        this.recorder.resumeRecording();
      } else {
        console.log("this.selectedCameraId", this.selectedCameraId);

        if (this.selectedCameraId !== null) {
          if (this.selectedTypeCamera == 1) {
            this.isRecording = true;
            this.isPaused = false;
            this.elapsedTime = 0;

            this.disabled = true;
            this.downloadReady = false;
            this.isVideoVisible = true;

            this.captureCamera((camera) => {
              const video = this.$refs.video;
              video.srcObject = camera;
              this.recorder = RecordRTC(camera, {
                type: "video",
              });
              this.recorder.startRecording();

              // release camera on stopRecording
              this.recorder.camera = camera;
              this.disabled = true;

              this.timer = setInterval(() => {
                this.elapsedTime += 1000;
              }, 1000);
            });
          } else {
            this.isRecording = true;
            this.isPaused = false;
            this.elapsedTime = 0;

            this.disabled = true;
            this.downloadReady = false;
            this.isVideoVisible = true;
            this.captureCamera((camera) => {
              const video = this.$refs.video;
              video.srcObject = camera;
              this.recorder = RecordRTC(camera, {
                type: "video",
              });

              // release camera on stopRecording
              this.recorder.camera = camera;
              this.disabled = true;
            });
          }
        }
      }
    },
    async stopRec() {
      this.disabled = false;

      this.recorder.stopRecording(this.stopRecordingCallback);

      this.isRecording = false;
      this.isPaused = false;

      // หยุดตัวจับเวลา
      clearInterval(this.timer);
    },
    // หยุดชั่วคราว
    async pauseRec() {
      if (this.recorder) {
        this.isRecording = false;
        this.isPaused = true;

        // หยุดตัวจับเวลา
        clearInterval(this.timer);

        // หยุดบันทึกชั่วคราว
        this.recorder.pauseRecording();
      }
    },
    async captureCamera(callback) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: { deviceId: { exact: this.selectedCameraId } },
        })
        .then(function (camera) {
          callback(camera);
        })
        .catch(function (error) {
          // alert("Unable to capture your camera. Please check console logs.");
          console.error(error);
        });
    },
    async stopRecordingCallback() {
      const video = this.$refs.video;
      video.src = video.srcObject = null;
      video.muted = true;
      video.volume = 0;
      this.recordedBlob = this.recorder.getBlob();
      video.src = URL.createObjectURL(this.recordedBlob);

      this.downloadReady = true;
      this.recorder.camera.stop();
      this.recorder.destroy();
      this.recorder = null;
      // this.isVideoVisible = false; // ซ่อนวีดีโอเมื่อหยุดการบันทึก
    },
    async getDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      console.log("this.videoDevices", this.videoDevices);
    },
    async startCamera() {
      if (this.selectedCameraId) {
        const video = this.$refs.video;
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: this.selectedCameraId } },
          audio: false,
        });
        video.srcObject = stream;
        await this.startRec();
      }
    },
    async stopCamera() {

      console.log("this.recorder.camera",this.recorder);
      if(this.recorder != null ){
        // this.recorder.camera.getTracks().forEach((track) => track.stop());
        // this.recorder.camera = null;
      }
      
      // this.recorder = null;
      // this.selectedCameraId = null;
      this.isPaused = false;
      this.isRecording = false;
      // this.$refs.video.style.backgroundColor = "black";
      // this.$refs.video.srcObject = null;
      // this.$refs.video.src = "";
      clearInterval(this.timer);
      this.elapsedTime = 0;
    },
    //ถ่ายภาพแปลงเป็น base64
    async captureImage() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      var imageUrl= canvas.toDataURL("image/png");
      var bodyImg = 
      {
      id : 0 ,
      mediaType :  0 ,
      orderMasterId : this.orderInfo.id ,
      packageNo :  this.orderItem.packages[this.orderItem.tab].packageNo ,
      pic : imageUrl ,
      created: null
      }

  
      // this.photoList.push(imageUrl);
      console.log(
        " this.orderItem.packages[this.orderItem.tab]",
        this.orderItem.packages[this.orderItem.tab]
      );

      this.orderItem.packages[this.orderItem.tab].photoList.push(bodyImg);
      this.$forceUpdate();

      // console.log( this.photoList);
    },
    //สำหรับเทสโหลดรูปภาพ
    async downloadRec() {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(this.recordedBlob);
      downloadLink.download = "recording.mp4";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    async downloadImage(image, index) {
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = `captured_image_${index + 1}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    //ส่วนของ function ที่เรียกใช้จากข้างนอก component
    openPhoto(linkPhoto) {
      this.picturesShow.ShowPhotoLink = linkPhoto;
      this.picturesShow.ShowPhoto = true;
    },
    closePhoto(value) {
      this.picturesShow.ShowPhoto = value;
      this.picturesShow.ShowPhotoLink = "";
    },
    openAddItemsIncrease(packageItem) {
      Swal.fire({
        title: "จำนวน",
        input: "number",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
          min: "1",
        },
        inputValue: "1",
        inputValidator: (value) => {
          const numValue = Number(value);
          console.log("value", numValue);
          if (numValue == 0) {
            return this.openAddItemsIncrease(packageItem);
          }
          this.addItemsIncrease = numValue;
          // if (this.packingCodeAdd != null) {
          //   this.addItemPacking(packageItem);
          // }
        },
      });
    },
    //ส่วนของ block website กดปิด
    beforeUnload(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    handleKeyDown(event) {
      if (event.key === "F5" || (event.ctrlKey && event.key === "r")) {
        event.preventDefault();
      }
    },

    //ส่วนของ import
    ChangeReferenceInfo,
    AlertError,
    formatMoney,
  },
};
</script>

<style scoped>
.lightgreen {
  background-color: #beffc1 !important;
}
.footer {
  position: fixed;
  left: 0;
  z-index: 3;
  bottom: 0;
  width: -webkit-fill-available;
  background-color: white;

  /* color: white; */
  /* text-align: center; */
  /* @media (min-width: 1264px) and (max-width: 1903px) {
    margin-left: 256px !important;
  }
  @media (min-width: 1904px) {
    margin-left: 256px !important;
  } */
}
.margin-left256 {
  @media (min-width: 1264px) and (max-width: 1903px) {
    margin-right: 256px !important;
  }
  @media (min-width: 1904px) {
    margin-right: 256px !important;
  }
}
.header {
  /* z-index: 3; */
  /* position: fixed; */
  left: 0;
  /* top: 66px; */
  width: -webkit-fill-available;
  background-color: white;
  /* text-align: center; */

  @media (min-width: 1264px) and (max-width: 1903px) {
    margin-right: 256px !important;
  }
  @media (min-width: 1904px) {
    margin-right: 256px !important;
  }
}
.jt_Start {
  display: flex;
  justify-content: flex-start !important;
}
.jt_end {
  display: flex;
  justify-content: flex-end;
}
</style>
